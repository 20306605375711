#teamDashboard {
	display: grid;
	grid-gap: 10px;
	width: 100%;
	height: 100%;
	min-height: 400px;
	max-height: calc(100vh - 6rem);
	grid-template-columns: 3fr 1fr;
	grid-template-areas:
			'cyphers points';

	#members {
		grid-area: cyphers;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		overflow-y: auto;
		background: rgba(255, 255, 255, 0.3);
		max-height: calc(100vh - 6rem);
		align-items: start;

		.cypher {
			border: 2px solid #fff;
			background: rgba(255, 255, 255, 0.3);
			padding: 2rem .5rem;
			margin: .5rem;
			width: 100%;
			text-align: center;
			border-radius: .5rem;
			word-break: break-word;

			&.decoding {
				position:relative;

				.background {
					background: rgba(255, 255, 255, 0.5);
					height: 100%;
					left: 0;
					top: 0;
					position: absolute;
					z-index: 1;
					transition: width .2s linear;
				}

				.message {
					font-family: starwars, sans-serif;
					z-index: 2;
					letter-spacing: 2px;
				}
				.remaining {
					position:absolute;
					bottom:0;
					right: 0;
					text-align: right;
					z-index: 3;
					margin: 0 .3rem;
				}
			}
		}
	}

	#stars {
		grid-area: stars;
		background: rgba(24, 32, 83, 0.8);
		border-radius: 10px;
	}

	#points {
		grid-area: points;
	}
}
