@use '../variables/grid';
@use 'sass:map';

@each $name, $values in grid.$template {
	@each $value in $values {
		.grid-template-#{$name}-#{$value} {
			grid-template-#{$name}: repeat(#{$value}, 1fr);
		}
	}
}

@each $name, $values in grid.$cols {
	@each $value in $values {
		.grid-col-#{$name}-#{$value} {
			@if $name == 'span' {
				grid-column: span #{$value};
			}
			@else {
				grid-column-#{$name}: #{$value};
			}
		}
	}
}

@each $name, $values in grid.$rows {
	@each $value in $values {
		.grid-row-#{$name}-#{$value} {
			@if $name == 'span' {
				grid-row: span #{$value};
			}
			@else {
				grid-row-#{$name}: #{$value};
			}
		}
	}
}

@each $breakpoint, $size in $grid-breakpoints {
	@include media-breakpoint-up($breakpoint) {
		@each $name, $values in grid.$template {
			@each $value in $values {
				.grid-template-#{$name}-#{$breakpoint}-#{$value} {
					grid-template-#{$name}: repeat(#{$value}, 1fr);
				}
			}
		}

		@each $name, $values in grid.$cols {
			@each $value in $values {
				.grid-col-#{$name}-#{$breakpoint}-#{$value} {
					@if $name == 'span' {
						grid-column: span #{$value};
					} @else {
						grid-column-#{$name}: #{$value};
					}
				}
			}
		}

		@each $name, $values in grid.$rows {
			@each $value in $values {
				.grid-row-#{$name}-#{$breakpoint}-#{$value} {
					@if $name == 'span' {
						grid-row: span #{$value};
					} @else {
						grid-row-#{$name}: #{$value};
					}
				}
			}
		}
	}
}
