@import "custom";

// BS Configuration
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

@import 'maps';

// Utilities
@import '~bootstrap/scss/utilities';
@import 'utilities';

// BS Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';

// BS Helpers
@import '~bootstrap/scss/helpers';

// BS Utilities
@import '~bootstrap/scss/utilities/api';

// Utilities
@import 'utilities/animations';
@import 'utilities/inputs';
@import 'utilities/text';
@import 'utilities/sizing';
@import 'utilities/grid';

// Components
@import 'components/loaders';
@import 'components/navbar';

// Pages
@import 'pages/rain';
@import 'pages/dashboard';
@import 'pages/members';

//@import "tinymce/skins/content/default/content.css";
//@import "tinymce/skins/ui/oxide/content.css";
//@import "tinymce/skins/ui/oxide/skin.css";
.tox-promotion {
	display: none !important;
}

@font-face {
	font-family: starwars;
	font-weight: normal;
	src: url(../font/STARWARS.ttf);
}

body {
	background: var(--bs-background);
	background-image:
			url('../images/stars.png'),
			linear-gradient(#000, var(--bs-background));
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	margin-left: auto;
	margin-right: auto;
	//max-width: 1140px;
	overflow-x: hidden;
	min-height: 100vh;
	//animation: backgroundMove 600s infinite linear;
}

@media print {
	body {
		background: none;
	}
}

footer {
	left: 0;
	bottom: 0;
}

.shake {
	animation: shake 200ms linear;
}

.container-vertical {
	padding-left: 110px;
}

lottie-player svg {
	height: 100%;
}

.indicie-print {
	page-break-inside: avoid;
	page-break-after: always;

	.indicie-column {
		width: 43mm;
		border-left: 1px solid black;
		border-right: 1px solid black;
		height: 100%;
	}

	svg {
		width: 35mm;
		height: 35mm;
	}
}

.question {
	img {
		max-width: 100%;
		height: auto;
	}
}
