// Generate letter spacing classes
@each $name, $size in $letter-spacings {
	.ls-#{$name} {
		letter-spacing: $size !important;
	}
}

// Responsive text classes
@each $breakpoint, $window in $grid-breakpoints {
	@include media-breakpoint-up($breakpoint) {

		// Letter spacings
		@each $name, $size in $letter-spacings {
			.ls-#{$breakpoint}-#{$name} {
				letter-spacing: $size !important;
			}
		}

		// Font sizes
		@each $name, $size in $font-sizes {
			.fs-#{$breakpoint}-#{name} {
				font-size: $size !important;
			}
		}

		.fs-#{$breakpoint}-default {
			font-size: 1em !important;
		}
	}
}

@each $name, $color in $custom-colors {
	$luminance: luminance($color);
	@if $luminance < 0.5 {
		.text-complimentary-#{$name} {
			color: #fff;
		}
	} @else {
		.text-complimentary-#{$name} {
			color: #000;
		}
	}
}
