.navbar-vertical {
	--contentWidth: 400px;
	--visibleWidth: 100px;
	height: 100vh !important;
	width: calc(var(--contentWidth) + var(--visibleWidth));
	display: grid;
	grid-template-columns: var(--contentWidth) var(--visibleWidth);
	grid-template-rows: 100vh;
	transition: transform 300ms ease;
	padding-top: 0;
	z-index: 20;

	&.fixed-left {
		position: fixed;
		top: 0;
		left: 0;
		grid-template-areas: 'content visible';

		&.collapse {
			transform: translateX(calc(-1 * var(--contentWidth)));
		}
	}
	&.fixed-right {
		position: fixed;
		top: 0;
		right: 0;
		grid-template-areas: 'visible content';
		&.collapse {
			transform: translateX(var(--contentWidth));
		}
	}
	&.collapse:not(.show) {
		display: grid;
	}

	&.collapse.show {
		transform: translateX(0);
	}

	.navbar-content {
		grid-area: content;
		width: 100%;
		height: 100%;
		background: rgba(255,255,255,0.02);
	}

	.navbar-visible {
		grid-area: visible;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
}
