@use "sass:map";

@each $name, $value in $custom-widths {
	.w-#{$name} {
		width: $value !important;
	}
}@each $name, $value in $custom-heights {
	.h-#{$name} {
		width: $value !important;
	}
}


@each $breakpoint, $window in $grid-breakpoints {
	@include media-breakpoint-up($breakpoint) {

		.flex-#{$breakpoint}-fill-same {
			flex: 1 1 0 !important;
		}

		@each $name, $size in $custom-widths {
			.w-#{$breakpoint}-#{$name} {
				width: $size !important;
			}
		}

		@each $name, $size in $custom-heights {
			.h-#{$breakpoint}-#{$name} {
				width: $size !important;
			}
		}

		@each $key, $utility in map_get-multiple($utilities, ("width", "max-width", "viewport-width", "min-viewport-width", "height", "max-height", "viewport-height", "min-viewport-height")) {
			@if type-of($utility) == "map" {
				$class: map.get($utility, "class");
				$property: map.get($utility, "property");
				@each $name, $value in map.get($utility, "values") {
					.#{$class}-#{$breakpoint}-#{$name} {
						#{$property}: $value !important;
					}
				}
			}
		}
	}
}
