.member {
	position: relative;

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		background: rgba(0,0,0,0.7);
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
