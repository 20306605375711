#rainContainer {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;

	.word {
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 2px;
		position:absolute;
		animation: fall 3s linear infinite;
	}
}

@keyframes fall {
	0% {
		top: -30px;
	}
	100% {
		top: calc(100vh + 30px);
	}
}
